import React, {useState, useEffect} from 'react';
// Imagens footer
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import EmailIcon from '@mui/icons-material/Email';

import "./Footer.css";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export default function Footer(props) {


    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    
    if (window.innerWidth <= 768) {
        return (<FooterMobile/>);
    } else {
        return (<FooterDesktop/>);
    }
    
}

function FooterDesktop(props) {
    return (
        <div class="home-footer">
            <div style={{display: "flex", padding: "0px 30px 0px 30px"}}>
                <div style={{margin: "15px", width: "50%"}}>
                    <p style={{color: "black", fontWeight: "bold",fontSize: "20px"}}>Links úteis</p>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <a href="https://verboencarnadobrasil.org/" class="footer-link-util footer-underline-effect" > Instituto Verbo Encarnado</a>
                        <a href="https://editora.verboencarnado.com.br/"  class="footer-link-util footer-underline-effect" >Editora Verbo Encarnado</a>
                        <a href="https://verboencarnadobrasil.org/exercicios-espirituais/"  class="footer-link-util footer-underline-effect" >Exercícios Espirituais</a>
                        <a href="https://nuestrocarisma.org/"  class="footer-link-util footer-underline-effect" >Nosso Carisma</a>
                        <a href="https://vozcatolica.com/"  class="footer-link-util footer-underline-effect" >Voz Católica</a>
                        <a href="https://www.teologoresponde.org/"  class="footer-link-util footer-underline-effect" >O Teólogo Responde</a>
                        <a href="https://40horas.org/pt/"  class="footer-link-util footer-underline-effect" >40 Horas Pelas Vocações</a>
                        <a href="https://www.corneliofabro.org/"  class="footer-link-util footer-underline-effect" >Projeto Cornelio Fabro</a>
                        <a href="https://www.servidorasdelsenor.org/pt/"  class="footer-link-util footer-underline-effect" >Servidoras do Senhor</a>
                    </div>
                </div>
                <div style={{margin: "15px", width: "50%"}}>
                    <p style={{color: "black", fontWeight: "bold",fontSize: "20px"}}>Nossas redes</p> 
                    <div>
                        <a href="https://www.facebook.com/portaldaliturgia/" style={{display: "flex", alignItems: "center", textDecoration: "none", color: "black"}}>
                            <FacebookIcon/>
                            <p class="footer-underline-effect" style={{color: "black", fontWeight: "bold",fontSize: "16px", textDecoration: "none", margin: "10px 10px 10px 4px"}}>portaldaliturgia</p>
                        </a>
                        <a href="https://www.instagram.com/portaldaliturgia/" style={{display: "flex", alignItems: "center", textDecoration: "none", color: "black"}}>
                            <InstagramIcon/>
                            <p class="footer-underline-effect" style={{color: "black", fontWeight: "bold",fontSize: "16px", textDecoration: "none", margin: "10px 10px 10px 4px"}}>portaldaliturgia</p>
                        </a>
                        <a href="https://www.youtube.com/@portaldaliturgia7822" style={{display: "flex", alignItems: "center", textDecoration: "none", color: "black"}}>
                            <YouTubeIcon/>
                            <p class="footer-underline-effect" style={{color: "black", fontWeight: "bold",fontSize: "16px", textDecoration: "none", margin: "10px 10px 10px 4px"}}>portaldaliturgia</p>
                        </a>
                    </div>
                </div>   
                <div style={{margin: "15px", width: "50%"}}>
                    <p style={{color: "black", fontWeight: "bold",fontSize: "20px"}}>Contato</p> 
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <p style={{color: "black", fontWeight: "bold",fontSize: "16px", textDecoration: "none"}}>liturgiaive@gmail.com</p>
                    </div>
                </div>               
                {/* <p>Copyright © {new Date().getFullYear()} Portal da Liturgia</p> */}
            </div>
        </div>
    )
}

function FooterMobile(props) {

    return (
        <div class="home-footer">
            <div style={{display: "flex", flexDirection: "column"}}>
                <div style={{margin: "15px", width: "100%"}}>
                    <p style={{color: "black", fontWeight: "bold",fontSize: "20px"}}>Links úteis</p>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <a href="https://verboencarnadobrasil.org/" class="footer-link-util footer-underline-effect" > Instituto Verbo Encarnado</a>
                        <a href="https://editora.verboencarnado.com.br/"  class="footer-link-util footer-underline-effect" >Editora Verbo Encarnado</a>
                        <a href="https://verboencarnadobrasil.org/exercicios-espirituais/"  class="footer-link-util footer-underline-effect" >Exercícios Espirituais</a>
                        <a href="https://nuestrocarisma.org/"  class="footer-link-util footer-underline-effect" >Nosso Carisma</a>
                        <a href="https://vozcatolica.com/"  class="footer-link-util footer-underline-effect" >Voz Católica</a>
                        <a href="https://www.teologoresponde.org/"  class="footer-link-util footer-underline-effect" >O Teólogo Responde</a>
                        <a href="https://40horas.org/pt/"  class="footer-link-util footer-underline-effect" >40 Horas Pelas Vocações</a>
                        <a href="https://www.corneliofabro.org/"  class="footer-link-util footer-underline-effect" >Projeto Cornelio Fabro</a>
                        <a href="https://www.servidorasdelsenor.org/pt/"  class="footer-link-util footer-underline-effect" >Servidoras do Senhor</a>
                    </div>
                </div>
                <div style={{margin: "15px", width: "100%"}}>
                    <p style={{color: "black", fontWeight: "bold",fontSize: "20px"}}>Nossas redes</p> 
                    <div>
                        <a href="https://www.facebook.com/portaldaliturgia/" style={{display: "flex", alignItems: "center", textDecoration: "none", color: "black"}}>
                            <FacebookIcon/>
                            <p class="footer-underline-effect" style={{color: "black", fontWeight: "bold",fontSize: "18px", textDecoration: "none", margin: "10px 10px 10px 4px"}}>portaldaliturgia</p>
                        </a>
                        <a href="https://www.instagram.com/portaldaliturgia/" style={{display: "flex", alignItems: "center", textDecoration: "none", color: "black"}}>
                            <InstagramIcon/>
                            <p class="footer-underline-effect" style={{color: "black", fontWeight: "bold",fontSize: "18px", textDecoration: "none", margin: "10px 10px 10px 4px"}}>portaldaliturgia</p>
                        </a>
                        <a href="https://www.youtube.com/@portaldaliturgia7822" style={{display: "flex", alignItems: "center", textDecoration: "none", color: "black"}}>
                            <YouTubeIcon/>
                            <p class="footer-underline-effect" style={{color: "black", fontWeight: "bold",fontSize: "18px", textDecoration: "none", margin: "10px 10px 10px 4px"}}>portaldaliturgia</p>
                        </a>
                    </div>
                </div>     
                <div style={{margin: "15px", width: "100%"}}>
                    <p style={{color: "black", fontWeight: "bold",fontSize: "20px"}}>Contato</p> 
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <a style={{color: "black", fontWeight: "bold",fontSize: "18px", textDecoration: "none"}} class="footer-link-util footer-underline-effect" href="mailto:liturgiaive@gmail.com">Enviar email</a>
                    </div>
                </div>                
                {/* <p>Copyright © {new Date().getFullYear()} Portal da Liturgia</p> */}
            </div>
        </div>
    )
}