import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import line from "../../images/musica-liturgica/home/line.png";
import Divider from '@mui/material-next/Divider';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { NavLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
// import ptBr from 'date-fns/locale/pt-b",r"';
// with date-fns v3.x
import { pt } from 'date-fns/locale/pt';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

// baseUrl
import {baseUrl} from "../../services/adalConfig.js";

import axios from "axios";

import "./Musica.css";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export default function TempoLiturgico(props) {

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [missa, setMissa] = useState();
    const navigate = useNavigate();
    const [listaMissas, setListaMissas] = useState({}); // Chama-se "lista" por motivos históricos, mas é um object

    const [lingua, setLingua] = useState({"portugues": true, "latim": true});

    const [musicas, setMusicas] = useState( {1:[], 2:[], 3:[], 4:[], 5:[], 6:[], 7:[], 8:[], 9:[], 10: [], 11: [], 11: [], 12: [], 13: [], 14: []});
    const [numeroMusicasMostrar, setNumeroMusicasMostrar] = useState({1: 5,2: 5,3: 5,4: 5,5: 5,6: 5,7: 5,8: 5,9: 5, 11:5, 14: 5});

    const [expandEntrada, setExpandEntrada] = useState(true);
    const [expandAtoPenitencial, setExpandAtoPenitencial] = useState(true);
    const [expandGloria, setExpandGloria] = useState(true);
    const [expandSalmo, setExpandSalmo] = useState(true);
    const [expandOfertorio, setExpandOfertorio] = useState(true);
    const [expandSanto, setExpandSanto] = useState(true);
    const [expandAmem, setExpandAmem] = useState(true);
    const [expandCordeiro, setExpandCordeiro] = useState(true);
    const [expandComunhao, setExpandComunhao] = useState(true);
    const [expandAclamacao, setExpandAclamacao] = useState(true);
    const [expandCantoFinal, setExpandCantoFinal] = useState(true);

    const [datasLiturgicas, setDatasLiturgicas] = useState([]);

    function handleAumentarItems(chave) {
        console.log("Entrou aqui");
        var tempItems = {...numeroMusicasMostrar};
        tempItems[chave] = tempItems[chave] + 15;
        setNumeroMusicasMostrar(tempItems);
    }

    function scrollTo(index) {
        var element = document.getElementById(index);
        element.scrollIntoView({ behavior: 'smooth' , block: "center", inline: "center"});
    }

    useEffect(() => {

        var url = window.location.href;

        var l = url.split("/");
        var url_missa = l[l.length - 1];

        var url = baseUrl + "/musicas-missa?url="+url_missa;

        axios.get(url)
            .then(resp => {
                console.log(resp.data);
                setMusicas(resp.data);
            });

    }, []);
 
    useEffect(() => {
       function handleResize() {
         setWindowDimensions(getWindowDimensions());
       }
       window.addEventListener('resize', handleResize);
       return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        var url = window.location.href;

        var l = url.split("/");
        var tempMissa = l[l.length - 1];

        var url = baseUrl + "/get-missas";

        axios.get(url)
            .then(resp => {
                console.log(resp.data);
                setListaMissas(resp.data);

                if ((tempMissa) in resp.data) {
                    setMissa(tempMissa);
                } else {
                    navigate("/musica-liturgica");
                    setMissa(false);
                }
            });
    }, []);

    var tempMusicas = {};
    for (let i = 1; i <= 14; i++ ) {
        tempMusicas[i] = [];
        for (let j = 0; j < musicas[i].length; j++) {
            if (lingua.portugues == true && musicas[i][j].lingua == 1) tempMusicas[i].push(musicas[i][j]);
            if (lingua.latim == true && musicas[i][j].lingua == 2) tempMusicas[i].push(musicas[i][j]);
        }
    }

    var url = window.location.href;
    var possuiGloria = true;
    if (url.includes("-advento") || url.includes("-quaresma")) possuiGloria = false;

    if (missa == "" || missa == false) return <div></div>;
 
    if (window.innerWidth <= 768) {
       return ( <MusicaPageMobile
                    listaMissas={listaMissas}
                    possuiGloria={possuiGloria}
                    missa={missa} 
                    lingua={lingua}
                    setLingua={setLingua}
                    musicas={tempMusicas}
                    handleAumentarItems={handleAumentarItems}
                    scrollTo={scrollTo}
                    numeroMusicasMostrar={numeroMusicasMostrar}
                    expandEntrada={expandEntrada}
                    setExpandEntrada={setExpandEntrada}
                    expandAclamacao={expandAclamacao}
                    setExpandAclamacao={setExpandAclamacao}
                    expandCantoFinal={expandCantoFinal}
                    setExpandCantoFinal={setExpandCantoFinal}
                    expandAtoPenitencial={expandAtoPenitencial}
                    setExpandAtoPenitencial={setExpandAtoPenitencial}
                    expandGloria={expandGloria}
                    setExpandGloria={setExpandGloria}
                    expandSalmo={expandSalmo}
                    setExpandSalmo={setExpandSalmo}
                    expandOfertorio={expandOfertorio}
                    setExpandOfertorio={setExpandOfertorio}
                    expandSanto={expandSanto}
                    setExpandSanto={setExpandSanto}
                    expandAmem={expandAmem}
                    setExpandAmem={setExpandAmem}
                    expandCordeiro={expandCordeiro}
                    setExpandCordeiro={setExpandCordeiro}
                    expandComunhao={expandComunhao}
                    setExpandComunhao={setExpandComunhao}
                />);
    } else {
       return ( <MusicaPageDesktop 
                    listaMissas={listaMissas}
                    possuiGloria={possuiGloria}
                    missa={missa} 
                    lingua={lingua}
                    setLingua={setLingua}
                    musicas={tempMusicas}
                    handleAumentarItems={handleAumentarItems}
                    scrollTo={scrollTo}
                    numeroMusicasMostrar={numeroMusicasMostrar}
                    expandEntrada={expandEntrada}
                    setExpandEntrada={setExpandEntrada}
                    expandAclamacao={expandAclamacao}
                    setExpandAclamacao={setExpandAclamacao}
                    expandCantoFinal={expandCantoFinal}
                    setExpandCantoFinal={setExpandCantoFinal}
                    expandAtoPenitencial={expandAtoPenitencial}
                    setExpandAtoPenitencial={setExpandAtoPenitencial}
                    expandGloria={expandGloria}
                    setExpandGloria={setExpandGloria}
                    expandSalmo={expandSalmo}
                    setExpandSalmo={setExpandSalmo}
                    expandOfertorio={expandOfertorio}
                    setExpandOfertorio={setExpandOfertorio}
                    expandSanto={expandSanto}
                    setExpandSanto={setExpandSanto}
                    expandAmem={expandAmem}
                    setExpandAmem={setExpandAmem}
                    expandCordeiro={expandCordeiro}
                    setExpandCordeiro={setExpandCordeiro}
                    expandComunhao={expandComunhao}
                    setExpandComunhao={setExpandComunhao}
                />);
    }
}

function MusicaPageDesktop(props) {

    return  <div style={{marginBottom: "100px", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", marginTop: "90px", overflow: "hidden"}}>
                <div class="musica-home-header" style={{marginTop: "20px", marginBottom: "20px"}}>
                    <Divider sx={{
                        "&::before, &::after": {
                        borderColor: "#bc1200",
                        },
                    }}>
                        {props.listaMissas[props.missa]}
                    </Divider>
                </div>
                <div style={{ width: "80%", display: "flex", justifyContent: "flex-start"}}>
                    {
                        (props.possuiGloria
                        &&
                        <div style={{display: "grid", gridTemplateColumns: "repeat(4, 1fr)", gridTemplateRows: "repeat(3, 40px)", width: "100%", justifyItems: "start", alignItems: "center"}}>
                                <li className="musica-page" onClick={() => props.scrollTo("1")}>Entrada</li>
                                <li className="musica-page" onClick={() => props.scrollTo("2")}>Ato Penitencial</li>
                                <li className="musica-page" onClick={() => props.scrollTo("3")}>Glória</li>
                                <li className="musica-page" onClick={() => props.scrollTo("4")}>Salmo Responsorial</li>
                                <li className="musica-page" onClick={() => props.scrollTo("10")}>Aclamação</li>
                                <li className="musica-page" onClick={() => props.scrollTo("5")}>Ofertório</li>
                                <li className="musica-page" onClick={() => props.scrollTo("6")}>Santo</li>
                                <li className="musica-page" onClick={() => props.scrollTo("7")}>Doxologia</li>
                                <li className="musica-page" onClick={() => props.scrollTo("8")}>Cordeiro de Deus</li>
                                <li className="musica-page" onClick={() => props.scrollTo("9")}>Comunhão</li>
                                <li className="musica-page" onClick={() => props.scrollTo("11")}>Canto Final</li>
                        </div>
                        )
                        ||
                        <div style={{display: "grid", gridTemplateColumns: "repeat(4, 1fr)", gridTemplateRows: "repeat(3, 40px)", width: "100%", justifyItems: "start", alignItems: "center"}}>
                                <li className="musica-page" onClick={() => props.scrollTo("1")}>Entrada</li>
                                <li className="musica-page" onClick={() => props.scrollTo("2")}>Ato Penitencial</li>
                                <li className="musica-page" onClick={() => props.scrollTo("4")}>Salmo Responsorial</li>
                                <li className="musica-page" onClick={() => props.scrollTo("5")}>Ofertório</li>
                                <li className="musica-page" onClick={() => props.scrollTo("10")}>Aclamação</li>
                                <li className="musica-page" onClick={() => props.scrollTo("6")}>Santo</li>
                                <li className="musica-page" onClick={() => props.scrollTo("7")}>Doxologia</li>
                                <li className="musica-page" onClick={() => props.scrollTo("8")}>Cordeiro de Deus</li>
                                <li className="musica-page" onClick={() => props.scrollTo("9")}>Comunhão</li>
                                <li className="musica-page" onClick={() => props.scrollTo("11")}>Canto Final</li>
                        </div>
                    }
                    
                    <div style={{width: "30%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center"}}>
                        <div>
                            <p style={{marginBottom: "10px", fontSize: "20px"}}>Língua</p>
                            <FormGroup >
                                <FormControlLabel 
                                    style={{height: "30px"}} 
                                    control={  <Checkbox sx={{
                                                                color: "#bc1200",
                                                                '&.Mui-checked': {
                                                                color: "#bc1200",
                                                                },
                                                            }} 
                                                            defaultChecked
                                                            checked={props.lingua.portugues}
                                                            onChange={(event) => props.setLingua({...props.lingua, portugues: event.target.checked})}
                                                            />} 
                                    label="Português" />
                                <FormControlLabel  
                                    style={{height: "30px"}} 
                                    control={  <Checkbox sx={{
                                                                color: "#bc1200",
                                                                '&.Mui-checked': {
                                                                color: "#bc1200",
                                                                },
                                                            }} 
                                                            defaultChecked
                                                            onChange={(event) => props.setLingua({...props.lingua, latim: event.target.checked})}
                                                            />} 
                                    label="Latim" />
                            </FormGroup>
                        </div>
                    </div>
                </div>
                {/*<p class="home-call-to-action" style={{marginBottom: "20px", marginTop: "40px", color: "#bc1200"}}><b><u>MONTE SUA PRÓPRIA MISSA >> </u></b></p>*/}                  
                <div style={{width: "100%"}}>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}} id="1">
                        <div class="musica-home-header" style={{marginTop: "20px", marginBottom: "20px"}}>
                            <Divider sx={{
                                "&::before, &::after": {
                                borderColor: "#bc1200",
                                },
                            }}>
                                <div style={{display: "flex", alignItems: "center"}}>
                                <ListItemButton onClick={() => props.setExpandEntrada(!props.expandEntrada)} style={{display: "flex", justifyContent: "center"}}>
                                        <p style={{fontSize: "25px"}}>Entrada</p>
                                        {props.expandEntrada ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>
                                </div>
                            </Divider>
                        </div>
                        <Collapse in={props.expandEntrada} timeout="auto" unmountOnExit style={{width: "60%"}}>
                            <ul style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "90%"}}>
                                {
                                    props.musicas[1].slice(0, props.numeroMusicasMostrar[1]).map((item) =>
                                      <li className="musica-page-musica-item"><NavLink to={"/musica-liturgica/musica/"+item.url} style={{color: "black", textDecoration: "none"}}>{item.nome} - {item.autor}</NavLink></li>
                                    )
                                }
                            </ul>
                            <p onClick={() => props.handleAumentarItems(1)} class="home-call-to-action" style={{marginBottom: "0px", marginTop: "0px", color: "#bc1200", width: "fit-content"}}><b>Ver mais </b></p>    
                        </Collapse>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}  id="2">
                        <div class="musica-home-header" style={{marginTop: "20px", marginBottom: "20px"}}>
                            <Divider sx={{
                                "&::before, &::after": {
                                borderColor: "#bc1200",
                                },
                            }}>
                                <div style={{display: "flex", alignItems: "center"}}>
                                <ListItemButton onClick={() => props.setExpandAtoPenitencial(!props.expandAtoPenitencial)} style={{display: "flex", justifyContent: "center"}}>
                                        <p style={{fontSize: "25px"}}>Ato Penitencial</p>
                                        {props.expandAtoPenitencial ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>
                                </div>
                            </Divider>
                        </div>
                        <Collapse in={props.expandAtoPenitencial} timeout="auto" unmountOnExit style={{width: "60%"}}>
                            <ul style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "90%"}}>
                                {
                                    props.musicas[2].slice(0, props.numeroMusicasMostrar[2]).map((item) =>
                                      <li className="musica-page-musica-item"><NavLink to={"/musica-liturgica/musica/"+item.url} style={{color: "black", textDecoration: "none"}}>{item.nome} - {item.autor}</NavLink></li>
                                    )
                                }
                            </ul>
                            <p onClick={() => props.handleAumentarItems(2)} class="home-call-to-action" style={{marginBottom: "0px", marginTop: "0px", color: "#bc1200", width: "fit-content"}}><b>Ver mais </b></p>    
                        </Collapse>
                    </div>
                    {
                        props.possuiGloria
                            &&
                        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}  id="3">
                            <div class="musica-home-header" style={{marginTop: "20px", marginBottom: "20px"}}>
                                <Divider sx={{
                                    "&::before, &::after": {
                                    borderColor: "#bc1200",
                                    },
                                }}>
                                    <div style={{display: "flex", alignItems: "center"}}>
                                    <ListItemButton onClick={() => props.setExpandGloria(!props.expandGloria)} style={{display: "flex", justifyContent: "center"}}>
                                            <p style={{fontSize: "25px"}}>Glória</p>
                                            {props.expandGloria ? <ExpandLess /> : <ExpandMore />}
                                        </ListItemButton>
                                    </div>
                                </Divider>
                            </div>
                            <Collapse in={props.expandGloria} timeout="auto" unmountOnExit style={{width: "60%"}}>
                                <ul style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "90%"}}>
                                    {
                                        props.musicas[3].slice(0, props.numeroMusicasMostrar[3]).map((item) =>
                                          <li className="musica-page-musica-item"><NavLink to={"/musica-liturgica/musica/"+item.url} style={{color: "black", textDecoration: "none"}}>{item.nome} - {item.autor}</NavLink></li>
                                        )
                                    }
                                </ul>
                                <p onClick={() => props.handleAumentarItems(3)} class="home-call-to-action" style={{marginBottom: "0px", marginTop: "0px", color: "#bc1200", width: "fit-content"}}><b>Ver mais </b></p>    
                            </Collapse>
                        </div>
                    }
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}  id="4">
                        <div class="musica-home-header" style={{marginTop: "20px", marginBottom: "20px"}}>
                            <Divider sx={{
                                "&::before, &::after": {
                                borderColor: "#bc1200",
                                },
                            }}>
                                <div style={{display: "flex", alignItems: "center"}}>
                                <ListItemButton onClick={() => props.setExpandSalmo(!props.expandSalmo)} style={{display: "flex", justifyContent: "center"}}>
                                        <p style={{fontSize: "25px"}}>Salmo Responsorial</p>
                                        {props.expandSalmo ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>
                                </div>
                            </Divider>
                        </div>
                        <Collapse in={props.expandSalmo} timeout="auto" unmountOnExit style={{width: "60%"}}>
                            <ul style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "90%"}}>
                                {
                                    props.musicas[4].slice(0, props.numeroMusicasMostrar[4]).map((item) =>
                                      <li className="musica-page-musica-item"><NavLink to={"/musica-liturgica/musica/"+item.url} style={{color: "black", textDecoration: "none"}}>{item.nome} - {item.autor}</NavLink></li>
                                    )
                                }
                            </ul>
                            <p onClick={() => props.handleAumentarItems(4)} class="home-call-to-action" style={{marginBottom: "0px", marginTop: "0px", color: "#bc1200", width: "fit-content"}}><b>Ver mais </b></p>    
                        </Collapse>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}} id="10">
                        <div class="musica-home-header" style={{marginTop: "20px", marginBottom: "20px"}}>
                            <Divider sx={{
                                "&::before, &::after": {
                                borderColor: "#bc1200",
                                },
                            }}>
                                <div style={{display: "flex", alignItems: "center"}}>
                                <ListItemButton onClick={() => props.setExpandAclamacao(!props.expandAclamacao)} style={{display: "flex", justifyContent: "center"}}>
                                        <p style={{fontSize: "25px"}}>Aclamação do Evangelho</p>
                                        {props.expandAclamacao ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>
                                </div>
                            </Divider>
                        </div>
                        <Collapse in={props.expandAclamacao} timeout="auto" unmountOnExit style={{width: "60%"}}>
                            <ul style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "90%"}}>
                                {
                                    props.musicas[14].slice(0, props.numeroMusicasMostrar[14]).map((item) =>
                                      <li className="musica-page-musica-item"><NavLink to={"/musica-liturgica/musica/"+item.url} style={{color: "black", textDecoration: "none"}}>{item.nome} - {item.autor}</NavLink></li>
                                    )
                                }
                            </ul>
                            <p onClick={() => props.handleAumentarItems(14)} class="home-call-to-action" style={{marginBottom: "0px", marginTop: "0px", color: "#bc1200", width: "fit-content"}}><b>Ver mais </b></p>    
                        </Collapse>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}  id="5">
                        <div class="musica-home-header" style={{marginTop: "20px", marginBottom: "20px"}}>
                            <Divider sx={{
                                "&::before, &::after": {
                                borderColor: "#bc1200",
                                },
                            }}>
                                <div style={{display: "flex", alignItems: "center"}}>
                                <ListItemButton onClick={() => props.setExpandOfertorio(!props.expandOfertorio)} style={{display: "flex", justifyContent: "center"}}>
                                        <p style={{fontSize: "25px"}}>Ofertório</p>
                                        {props.expandOfertorio ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>
                                </div>
                            </Divider>
                        </div>
                        <Collapse in={props.expandOfertorio} timeout="auto" unmountOnExit style={{width: "60%"}}>
                            <ul style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "90%"}}>
                                {
                                    props.musicas[5].slice(0, props.numeroMusicasMostrar[5]).map((item) =>
                                      <li className="musica-page-musica-item"><NavLink to={"/musica-liturgica/musica/"+item.url} style={{color: "black", textDecoration: "none"}}>{item.nome} - {item.autor}</NavLink></li>
                                    )
                                }
                            </ul>
                            <p onClick={() => props.handleAumentarItems(5)} class="home-call-to-action" style={{marginBottom: "0px", marginTop: "0px", color: "#bc1200", width: "fit-content"}}><b>Ver mais </b></p>    
                        </Collapse>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}  id="6">
                        <div class="musica-home-header" style={{marginTop: "20px", marginBottom: "20px"}}>
                            <Divider sx={{
                                "&::before, &::after": {
                                borderColor: "#bc1200",
                                },
                            }}>
                                <div style={{display: "flex", alignItems: "center"}}>
                                <ListItemButton onClick={() => props.setExpandSanto(!props.expandSanto)} style={{display: "flex", justifyContent: "center"}}>
                                        <p style={{fontSize: "25px"}}>Santo</p>
                                        {props.expandSanto ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>
                                </div>
                            </Divider>
                        </div>
                        <Collapse in={props.expandSanto} timeout="auto" unmountOnExit style={{width: "60%"}}>
                            <ul style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "90%"}}>
                                {
                                    props.musicas[6].slice(0, props.numeroMusicasMostrar[6]).map((item) =>
                                      <li className="musica-page-musica-item"><NavLink to={"/musica-liturgica/musica/"+item.url} style={{color: "black", textDecoration: "none"}}>{item.nome} - {item.autor}</NavLink></li>
                                    )
                                }
                            </ul>
                            <p onClick={() => props.handleAumentarItems(6)} class="home-call-to-action" style={{marginBottom: "0px", marginTop: "0px", color: "#bc1200", width: "fit-content"}}><b>Ver mais </b></p>    
                        </Collapse>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}  id="7">
                        <div class="musica-home-header" style={{marginTop: "20px", marginBottom: "20px"}}>
                            <Divider sx={{
                                "&::before, &::after": {
                                borderColor: "#bc1200",
                                },
                            }}>
                                <div style={{display: "flex", alignItems: "center"}}>
                                <ListItemButton onClick={() => props.setExpandAmem(!props.expandAmem)} style={{display: "flex", justifyContent: "center"}}>
                                        <p style={{fontSize: "25px"}}>Doxologia</p>
                                        {props.expandAmem ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>
                                </div>
                            </Divider>
                        </div>
                        <Collapse in={props.expandAmem} timeout="auto" unmountOnExit style={{width: "60%"}}>
                            <ul style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "90%"}}>
                                {
                                    props.musicas[7].slice(0, props.numeroMusicasMostrar[7]).map((item) =>
                                      <li className="musica-page-musica-item"><NavLink to={"/musica-liturgica/musica/"+item.url} style={{color: "black", textDecoration: "none"}}>{item.nome} - {item.autor}</NavLink></li>
                                    )
                                }
                            </ul>
                            <p onClick={() => props.handleAumentarItems(7)} class="home-call-to-action" style={{marginBottom: "0px", marginTop: "0px", color: "#bc1200", width: "fit-content"}}><b>Ver mais </b></p>    
                        </Collapse>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}  id="8">
                        <div class="musica-home-header" style={{marginTop: "20px", marginBottom: "20px"}}>
                            <Divider sx={{
                                "&::before, &::after": {
                                borderColor: "#bc1200",
                                },
                            }}>
                                <div style={{display: "flex", alignItems: "center"}}>
                                <ListItemButton onClick={() => props.setExpandCordeiro(!props.expandCordeiro)} style={{display: "flex", justifyContent: "center"}}>
                                        <p style={{fontSize: "25px"}}>Cordeiro de Deus</p>
                                        {props.expandCordeiro ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>
                                </div>
                            </Divider>
                        </div>
                        <Collapse in={props.expandCordeiro} timeout="auto" unmountOnExit style={{width: "60%"}}>
                            <ul style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "90%"}}>
                                {
                                    props.musicas[8].slice(0, props.numeroMusicasMostrar[8]).map((item) =>
                                      <li className="musica-page-musica-item"><NavLink to={"/musica-liturgica/musica/"+item.url} style={{color: "black", textDecoration: "none"}}>{item.nome} - {item.autor}</NavLink></li>
                                    )
                                }
                            </ul>
                            <p onClick={() => props.handleAumentarItems(8)} class="home-call-to-action" style={{marginBottom: "0px", marginTop: "0px", color: "#bc1200", width: "fit-content"}}><b>Ver mais </b></p>    
                        </Collapse>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}  id="9">
                        <div class="musica-home-header" style={{marginTop: "20px", marginBottom: "20px"}}>
                            <Divider sx={{
                                "&::before, &::after": {
                                borderColor: "#bc1200",
                                },
                            }}>
                                <div style={{display: "flex", alignItems: "center"}}>
                                <ListItemButton onClick={() => props.setExpandComunhao(!props.expandComunhao)} style={{display: "flex", justifyContent: "center"}}>
                                        <p style={{fontSize: "25px"}}>Comunhão</p>
                                        {props.expandComunhao ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>
                                </div>
                            </Divider>
                        </div>
                        <Collapse in={props.expandComunhao} timeout="auto" unmountOnExit style={{width: "60%"}}>
                            <ul style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "90%"}}>
                                {
                                    props.musicas[9].slice(0, props.numeroMusicasMostrar[9]).map((item) =>
                                      <li className="musica-page-musica-item"><NavLink to={"/musica-liturgica/musica/"+item.url} style={{color: "black", textDecoration: "none"}}>{item.nome} - {item.autor}</NavLink></li>
                                    )
                                }
                            </ul>
                            <p onClick={() => props.handleAumentarItems(9)} class="home-call-to-action" style={{marginBottom: "0px", marginTop: "0px", color: "#bc1200", width: "fit-content"}}><b>Ver mais </b></p>    
                        </Collapse>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}} id="11">
                        <div class="musica-home-header" style={{marginTop: "20px", marginBottom: "20px"}}>
                            <Divider sx={{
                                "&::before, &::after": {
                                borderColor: "#bc1200",
                                },
                            }}>
                                <div style={{display: "flex", alignItems: "center"}}>
                                <ListItemButton onClick={() => props.setExpandCantoFinal(!props.expandCantoFinal)} style={{display: "flex", justifyContent: "center"}}>
                                        <p style={{fontSize: "25px"}}>Canto Final</p>
                                        {props.expandCantoFinal ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>
                                </div>
                            </Divider>
                        </div>
                        <Collapse in={props.expandCantoFinal} timeout="auto" unmountOnExit style={{width: "60%"}}>
                            <ul style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "90%"}}>
                                {
                                    props.musicas[11].slice(0, props.numeroMusicasMostrar[11]).map((item) =>
                                      <li className="musica-page-musica-item"><NavLink to={"/musica-liturgica/musica/"+item.url} style={{color: "black", textDecoration: "none"}}>{item.nome} - {item.autor}</NavLink></li>
                                    )
                                }
                            </ul>
                            <p onClick={() => props.handleAumentarItems(11)} class="home-call-to-action" style={{marginBottom: "0px", marginTop: "0px", color: "#bc1200", width: "fit-content"}}><b>Ver mais </b></p>    
                        </Collapse>
                    </div>
                </div>
            </div>;
}

function MusicaPageMobile(props) {

    return (
            <div style={{marginBottom: "100px", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", marginTop: "90px", overflow: "hidden"}}>
                    <div class="musica-home-header" style={{marginTop: "20px", marginBottom: "0px"}}> 
                        <Divider 
                            sx={{
                                "&::before, &::after": {
                                borderColor: "#bc1200",
                                },
                            }}
                            style={{
                                fontSize: "25px",
                                fontSize: "6.5vw"
                            }}
                            >
                            {props.listaMissas[props.missa]}
                        </Divider>
                    </div>
                    <div style={{display: "flex", width: "100%", flexDirection: "column"}}>
                        {/*<div style={{width: "75%", display: "flex"}}>
                            <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", textAlign: "center"}}>
                                <p className="musica-page" onClick={() => props.scrollTo("1")}>Entrada</p>
                                <p className="musica-page" onClick={() => props.scrollTo("2")}>Ato Penitencial</p>
                                <p className="musica-page" onClick={() => props.scrollTo("3")}>Glória</p>
                            </div>
                            <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", textAlign: "center"}}>
                                <p className="musica-page" onClick={() => props.scrollTo("4")}>Salmo</p>
                                <p className="musica-page" onClick={() => props.scrollTo("5")}>Ofertório</p>
                                <p className="musica-page" onClick={() => props.scrollTo("6")}>Santo</p>
                            </div>
                            <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", textAlign: "center"}}>
                                <p className="musica-page" onClick={() => props.scrollTo("7")}>Doxologia</p>
                                <p className="musica-page" onClick={() => props.scrollTo("8")}>Cordeiro de Deus</p>
                                <p className="musica-page" onClick={() => props.scrollTo("9")}>Comunhão</p>
                            </div> 
                        </div>
                        */}
                        <div style={{width: "100%", display: "flex", justifyContent: "center",    flexDirection: "column", alignItems: "center", height: "50px",    marginTop: "10px"}}>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <p style={{marginRight: "20px", fontSize: "20px", width: "fit-content"}}>Língua</p>
                            <FormGroup style={{display: "flex", flexDirection: "row"}}>
                                <FormControlLabel 
                                    style={{height: "30px"}} 
                                    control={  <Checkbox sx={{
                                                                color: "#bc1200",
                                                                '&.Mui-checked': {
                                                                color: "#bc1200",
                                                                },
                                                            }} 
                                                            defaultChecked
                                                            checked={props.lingua.portugues}
                                                            onChange={(event) => props.setLingua({...props.lingua, portugues: event.target.checked})}
                                                            />} 
                                    label="Português" />
                                <FormControlLabel  
                                    style={{height: "30px"}} 
                                    control={  <Checkbox sx={{
                                                                color: "#bc1200",
                                                                '&.Mui-checked': {
                                                                color: "#bc1200",
                                                                },
                                                            }} 
                                                            defaultChecked
                                                            onChange={(event) => props.setLingua({...props.lingua, latim: event.target.checked})}
                                                            />} 
                                    label="Latim" />
                            </FormGroup>
                        </div>
                    </div>
                    <div style={{width: "100%"}}>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}} id="1">
                        <div class="musica-home-header" style={{marginTop: "8px", marginBottom: "10px"}}>
                            <Divider sx={{
                                "&::before, &::after": {
                                borderColor: "#bc1200",
                                },
                            }}>
                                <div style={{display: "flex", alignItems: "center"}}>
                                <ListItemButton onClick={() => props.setExpandEntrada(!props.expandEntrada)} style={{display: "flex", justifyContent: "center"}}>
                                        <p style={{fontSize: "25px"}}>Entrada</p>
                                        {props.expandEntrada ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>
                                </div>
                            </Divider>
                        </div>
                        <Collapse in={props.expandEntrada} timeout="auto" unmountOnExit style={{width: "80%"}}>
                            <ul style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "90%"}}>
                                {
                                    props.musicas[1].slice(0, props.numeroMusicasMostrar[1]).map((item) =>
                                      <li className="musica-page-musica-item"><NavLink to={"/musica-liturgica/musica/"+item.url} style={{color: "black", textDecoration: "none"}}>{item.nome} - {item.autor}</NavLink></li>
                                    )
                                }
                            </ul>
                            <p onClick={() => props.handleAumentarItems(1)} class="home-call-to-action" style={{marginBottom: "0px", marginTop: "0px", color: "#bc1200", width: "fit-content"}}><b>Ver mais </b></p>    
                        </Collapse>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}  id="2">
                        <div class="musica-home-header" style={{marginTop: "8px", marginBottom: "10px"}}>
                            <Divider sx={{
                                "&::before, &::after": {
                                borderColor: "#bc1200",
                                },
                            }}>
                                <div style={{display: "flex", alignItems: "center"}}>
                                <ListItemButton onClick={() => props.setExpandAtoPenitencial(!props.expandAtoPenitencial)} style={{display: "flex", justifyContent: "center"}}>
                                        <p style={{fontSize: "25px"}}>Ato Penitencial</p>
                                        {props.expandAtoPenitencial ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>
                                </div>
                            </Divider>
                        </div>
                        <Collapse in={props.expandAtoPenitencial} timeout="auto" unmountOnExit style={{width: "80%"}}>
                            <ul style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "90%"}}>
                                {
                                    props.musicas[2].slice(0, props.numeroMusicasMostrar[2]).map((item) =>
                                      <li className="musica-page-musica-item"><NavLink to={"/musica-liturgica/musica/"+item.url} style={{color: "black", textDecoration: "none"}}>{item.nome} - {item.autor}</NavLink></li>
                                    )
                                }
                            </ul>
                            <p onClick={() => props.handleAumentarItems(2)} class="home-call-to-action" style={{marginBottom: "0px", marginTop: "0px", color: "#bc1200", width: "fit-content"}}><b>Ver mais </b></p>    
                        </Collapse>
                    </div>
                    {
                        props.possuiGloria
                            &&
                        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}  id="3">
                            <div class="musica-home-header" style={{marginTop: "8px", marginBottom: "10px"}}>
                                <Divider sx={{
                                    "&::before, &::after": {
                                    borderColor: "#bc1200",
                                    },
                                }}>
                                    <div style={{display: "flex", alignItems: "center"}}>
                                    <ListItemButton onClick={() => props.setExpandGloria(!props.expandGloria)} style={{display: "flex", justifyContent: "center"}}>
                                            <p style={{fontSize: "25px"}}>Glória</p>
                                            {props.expandGloria ? <ExpandLess /> : <ExpandMore />}
                                        </ListItemButton>
                                    </div>
                                </Divider>
                            </div>
                            <Collapse in={props.expandGloria} timeout="auto" unmountOnExit style={{width: "80%"}}>
                                <ul style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "90%"}}>
                                    {
                                        props.musicas[3].slice(0, props.numeroMusicasMostrar[3]).map((item) =>
                                          <li className="musica-page-musica-item"><NavLink to={"/musica-liturgica/musica/"+item.url} style={{color: "black", textDecoration: "none"}}>{item.nome} - {item.autor}</NavLink></li>
                                        )
                                    }
                                </ul>
                                <p onClick={() => props.handleAumentarItems(3)} class="home-call-to-action" style={{marginBottom: "0px", marginTop: "0px", color: "#bc1200", width: "fit-content"}}><b>Ver mais </b></p>    
                            </Collapse>
                        </div>
                    }
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}  id="4">
                        <div class="musica-home-header" style={{marginTop: "8px", marginBottom: "10px"}}>
                            <Divider sx={{
                                "&::before, &::after": {
                                borderColor: "#bc1200",
                                },
                            }}>
                                <div style={{display: "flex", alignItems: "center"}}>
                                <ListItemButton onClick={() => props.setExpandSalmo(!props.expandSalmo)} style={{display: "flex", justifyContent: "center"}}>
                                        <p style={{fontSize: "25px"}}>Salmo Responsorial</p>
                                        {props.expandSalmo ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>
                                </div>
                            </Divider>
                        </div>
                        <Collapse in={props.expandSalmo} timeout="auto" unmountOnExit style={{width: "80%"}}>
                            <ul style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "90%"}}>
                                {
                                    props.musicas[4].slice(0, props.numeroMusicasMostrar[4]).map((item) =>
                                      <li className="musica-page-musica-item"><NavLink to={"/musica-liturgica/musica/"+item.url} style={{color: "black", textDecoration: "none"}}>{item.nome} - {item.autor}</NavLink></li>
                                    )
                                }
                            </ul>
                            <p onClick={() => props.handleAumentarItems(4)} class="home-call-to-action" style={{marginBottom: "0px", marginTop: "0px", color: "#bc1200", width: "fit-content"}}><b>Ver mais </b></p>    
                        </Collapse>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}  id="10">
                        <div class="musica-home-header" style={{marginTop: "8px", marginBottom: "10px"}}>
                            <Divider sx={{
                                "&::before, &::after": {
                                borderColor: "#bc1200",
                                },
                            }}>
                                <div style={{display: "flex", alignItems: "center"}}>
                                <ListItemButton onClick={() => props.setExpandAclamacao(!props.expandAclamacao)} style={{display: "flex", justifyContent: "center"}}>
                                        <p style={{fontSize: "25px"}}>Aclamação</p>
                                        {props.expandAclamacao ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>
                                </div>
                            </Divider>
                        </div>
                        <Collapse in={props.expandAclamacao} timeout="auto" unmountOnExit style={{width: "80%"}}>
                            <ul style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "90%"}}>
                                {
                                    props.musicas[14].slice(0, props.numeroMusicasMostrar[14]).map((item) =>
                                      <li className="musica-page-musica-item"><NavLink to={"/musica-liturgica/musica/"+item.url} style={{color: "black", textDecoration: "none"}}>{item.nome} - {item.autor}</NavLink></li>
                                    )
                                }
                            </ul>
                            <p onClick={() => props.handleAumentarItems(14)} class="home-call-to-action" style={{marginBottom: "0px", marginTop: "0px", color: "#bc1200", width: "fit-content"}}><b>Ver mais </b></p>    
                        </Collapse>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}  id="5">
                        <div class="musica-home-header" style={{marginTop: "8px", marginBottom: "10px"}}>
                            <Divider sx={{
                                "&::before, &::after": {
                                borderColor: "#bc1200",
                                },
                            }}>
                                <div style={{display: "flex", alignItems: "center"}}>
                                <ListItemButton onClick={() => props.setExpandOfertorio(!props.expandOfertorio)} style={{display: "flex", justifyContent: "center"}}>
                                        <p style={{fontSize: "25px"}}>Ofertório</p>
                                        {props.expandOfertorio ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>
                                </div>
                            </Divider>
                        </div>
                        <Collapse in={props.expandOfertorio} timeout="auto" unmountOnExit style={{width: "80%"}}>
                            <ul style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "90%"}}>
                                {
                                    props.musicas[5].slice(0, props.numeroMusicasMostrar[5]).map((item) =>
                                      <li className="musica-page-musica-item"><NavLink to={"/musica-liturgica/musica/"+item.url} style={{color: "black", textDecoration: "none"}}>{item.nome} - {item.autor}</NavLink></li>
                                    )
                                }
                            </ul>
                            <p onClick={() => props.handleAumentarItems(5)} class="home-call-to-action" style={{marginBottom: "0px", marginTop: "0px", color: "#bc1200", width: "fit-content"}}><b>Ver mais </b></p>    
                        </Collapse>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}  id="6">
                        <div class="musica-home-header" style={{marginTop: "8px", marginBottom: "10px"}}>
                            <Divider sx={{
                                "&::before, &::after": {
                                borderColor: "#bc1200",
                                },
                            }}>
                                <div style={{display: "flex", alignItems: "center"}}>
                                <ListItemButton onClick={() => props.setExpandSanto(!props.expandSanto)} style={{display: "flex", justifyContent: "center"}}>
                                        <p style={{fontSize: "25px"}}>Santo</p>
                                        {props.expandSanto ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>
                                </div>
                            </Divider>
                        </div>
                        <Collapse in={props.expandSanto} timeout="auto" unmountOnExit style={{width: "80%"}}>
                            <ul style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "90%"}}>
                                {
                                    props.musicas[6].slice(0, props.numeroMusicasMostrar[6]).map((item) =>
                                      <li className="musica-page-musica-item"><NavLink to={"/musica-liturgica/musica/"+item.url} style={{color: "black", textDecoration: "none"}}>{item.nome} - {item.autor}</NavLink></li>
                                    )
                                }
                            </ul>
                            <p onClick={() => props.handleAumentarItems(6)} class="home-call-to-action" style={{marginBottom: "0px", marginTop: "0px", color: "#bc1200", width: "fit-content"}}><b>Ver mais </b></p>    
                        </Collapse>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}  id="7">
                        <div class="musica-home-header" style={{marginTop: "8px", marginBottom: "10px"}}>
                            <Divider sx={{
                                "&::before, &::after": {
                                borderColor: "#bc1200",
                                },
                            }}>
                                <div style={{display: "flex", alignItems: "center"}}>
                                <ListItemButton onClick={() => props.setExpandAmem(!props.expandAmem)} style={{display: "flex", justifyContent: "center"}}>
                                        <p style={{fontSize: "25px"}}>Doxologia</p>
                                        {props.expandAmem ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>
                                </div>
                            </Divider>
                        </div>
                        <Collapse in={props.expandAmem} timeout="auto" unmountOnExit style={{width: "80%"}}>
                            <ul style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "90%"}}>
                                {
                                    props.musicas[7].slice(0, props.numeroMusicasMostrar[7]).map((item) =>
                                      <li className="musica-page-musica-item"><NavLink to={"/musica-liturgica/musica/"+item.url} style={{color: "black", textDecoration: "none"}}>{item.nome} - {item.autor}</NavLink></li>
                                    )
                                }
                            </ul>
                            <p onClick={() => props.handleAumentarItems(7)} class="home-call-to-action" style={{marginBottom: "0px", marginTop: "0px", color: "#bc1200", width: "fit-content"}}><b>Ver mais </b></p>    
                        </Collapse>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}  id="8">
                        <div class="musica-home-header" style={{marginTop: "8px", marginBottom: "10px"}}>
                            <Divider sx={{
                                "&::before, &::after": {
                                borderColor: "#bc1200",
                                },
                            }}>
                                <div style={{display: "flex", alignItems: "center"}}>
                                <ListItemButton onClick={() => props.setExpandCordeiro(!props.expandCordeiro)} style={{display: "flex", justifyContent: "center"}}>
                                        <p style={{fontSize: "25px"}}>Cordeiro de Deus</p>
                                        {props.expandCordeiro ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>
                                </div>
                            </Divider>
                        </div>
                        <Collapse in={props.expandCordeiro} timeout="auto" unmountOnExit style={{width: "80%"}}>
                            <ul style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "90%"}}>
                                {
                                    props.musicas[8].slice(0, props.numeroMusicasMostrar[8]).map((item) =>
                                      <li className="musica-page-musica-item"><NavLink to={"/musica-liturgica/musica/"+item.url} style={{color: "black", textDecoration: "none"}}>{item.nome} - {item.autor}</NavLink></li>
                                    )
                                }
                            </ul>
                            <p onClick={() => props.handleAumentarItems(8)} class="home-call-to-action" style={{marginBottom: "0px", marginTop: "0px", color: "#bc1200", width: "fit-content"}}><b>Ver mais </b></p>    
                        </Collapse>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}  id="9">
                        <div class="musica-home-header" style={{marginTop: "8px", marginBottom: "10px"}}>
                            <Divider sx={{
                                "&::before, &::after": {
                                borderColor: "#bc1200",
                                },
                            }}>
                                <div style={{display: "flex", alignItems: "center"}}>
                                <ListItemButton onClick={() => props.setExpandComunhao(!props.expandComunhao)} style={{display: "flex", justifyContent: "center"}}>
                                        <p style={{fontSize: "25px"}}>Comunhão</p>
                                        {props.expandComunhao ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>
                                </div>
                            </Divider>
                        </div>
                        <Collapse in={props.expandComunhao} timeout="auto" unmountOnExit style={{width: "80%"}}>
                            <ul style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "90%"}}>
                                {
                                    props.musicas[9].slice(0, props.numeroMusicasMostrar[9]).map((item) =>
                                      <li className="musica-page-musica-item"><NavLink to={"/musica-liturgica/musica/"+item.url} style={{color: "black", textDecoration: "none"}}>{item.nome} - {item.autor}</NavLink></li>
                                    )
                                }
                            </ul>
                            <p onClick={() => props.handleAumentarItems(9)} class="home-call-to-action" style={{marginBottom: "0px", marginTop: "0px", color: "#bc1200", width: "fit-content"}}><b>Ver mais </b></p>    
                        </Collapse>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}  id="10">
                        <div class="musica-home-header" style={{marginTop: "8px", marginBottom: "10px"}}>
                            <Divider sx={{
                                "&::before, &::after": {
                                borderColor: "#bc1200",
                                },
                            }}>
                                <div style={{display: "flex", alignItems: "center"}}>
                                <ListItemButton onClick={() => props.setExpandCantoFinal(!props.expandCantoFinal)} style={{display: "flex", justifyContent: "center"}}>
                                        <p style={{fontSize: "25px"}}>Canto Final</p>
                                        {props.expandCantoFinal ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>
                                </div>
                            </Divider>
                        </div>
                        <Collapse in={props.expandCantoFinal} timeout="auto" unmountOnExit style={{width: "80%"}}>
                            <ul style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "90%"}}>
                                {
                                    props.musicas[11].slice(0, props.numeroMusicasMostrar[11]).map((item) =>
                                      <li className="musica-page-musica-item"><NavLink to={"/musica-liturgica/musica/"+item.url} style={{color: "black", textDecoration: "none"}}>{item.nome} - {item.autor}</NavLink></li>
                                    )
                                }
                            </ul>
                            <p onClick={() => props.handleAumentarItems(11)} class="home-call-to-action" style={{marginBottom: "0px", marginTop: "0px", color: "#bc1200", width: "fit-content"}}><b>Ver mais </b></p>    
                        </Collapse>
                    </div>
                </div>
                    </div>
        </div>);
}