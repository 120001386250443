import React, {useState, useEffect} from 'react';
import line from "../../images/musica-liturgica/home/line.png";
import Divider from '@mui/material-next/Divider';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { NavLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import axios from 'axios';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// import ptBr from 'date-fns/locale/pt-br';
// with date-fns v3.x
import { pt } from 'date-fns/locale/pt';

// baseUrl
import {baseUrl} from "../../services/adalConfig.js";

import "./Subsidios.css";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export default function TemaOracoes(props) {

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  	const [oracoes, setOracoes] = useState([]);
  	const [nomeSubdivisaoSubsidio, setNomeSubdivisaoSubsidio] = useState("");
  	const [value, setValue] = useState("");

    useEffect(() => {
       function handleResize() {
         setWindowDimensions(getWindowDimensions());
       }
       window.addEventListener('resize', handleResize);
       return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {

    	var url = window.location.href;

    	var l = url.split("/");
        var tipoOracao = l[l.length - 1];

    	var getUrl = baseUrl + "/subsidios-por-subdivisao?url="+tipoOracao;

        axios.get(getUrl)
            .then(resp => {
            	console.log("resp is");
            	console.log(resp.data);
                setNomeSubdivisaoSubsidio(resp.data.nome_subdivisao_subsidio);
                setOracoes(resp.data.dados);
            });
    }, []);

    var oracoesPesquisa = [];

    if (value != "" && value != null) {
        for (let i = 0; i < oracoes.length; i++) {
            if (oracoes[i].nome_documento.toLowerCase().includes(value.toLowerCase())) {
                oracoesPesquisa.push(oracoes[i]);
            }
        }
    } else {
        oracoesPesquisa = oracoes;
    }
 
    if (window.innerWidth <= 768) {
       return (
        <TemaOracaoMobile 
        	oracoes={oracoesPesquisa}
        	nomeSubdivisaoSubsidio={nomeSubdivisaoSubsidio}
        	value={value}
        	setValue={setValue}
        />);
    } else {
       return (
        <TemaOracaoDesktop 
        	oracoes={oracoesPesquisa}
        	nomeSubdivisaoSubsidio={nomeSubdivisaoSubsidio}
        	value={value}
        	setValue={setValue}
        />);
    }
}

function TemaOracaoDesktop(props) {
    
    return (
        <div style={{marginBottom: "100px", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", marginTop: "110px", overflow: "hidden"}}>
            <div class="musica-home-header" style={{marginTop: "20px"}}>
                <Divider sx={{
                    "&::before, &::after": {
                    borderColor: "#bc1200",
                    },
                }}>
                    {props.nomeSubdivisaoSubsidio}
                </Divider>
            </div>
            <div style={{width: "40%", height: "100px", display: "flex", flexDirection: "column", justifyContent: "center"}}>
            <TextField 
                label="Pesquisar..." 
                value={props.value}
                onChange={(event) => {
                    props.setValue(event.target.value);
                }}
            />
            </div>
            <div style={{marginBottom: "0px", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", overflow: "hidden", width: "40%"}}>
                <ul style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "90%"}}>
                	{
                        props.oracoes.map((item) =>
                          <li className="musica-page-musica-item"><a target="_blank" href={item.caminho_arquivo} style={{color: "black", textDecoration: "none"}}>{item.nome_documento}</a></li>
                        )
                    }
                </ul>
            </div>
        </div>);

}

function TemaOracaoMobile(props) {
    
    return (
        <div style={{marginBottom: "100px", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", marginTop: "110px", overflow: "hidden"}}>
            <div class="musica-home-header" style={{marginTop: "20px"}}>
                <Divider sx={{
                    "&::before, &::after": {
                    borderColor: "#bc1200"
                    },
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr",
                    justifyItems: "center"
                }}>
                    <p style={{whiteSpace: "break-spaces", fontSize: "calc(2rem + 0.3vw)", maxWidth: "80vw", width: "max-content"}}>
                    {props.nomeSubdivisaoSubsidio}
                    </p>
                    
                </Divider>
            </div>
            <div style={{width: "80%", height: "100px", display: "flex", flexDirection: "column", justifyContent: "center"}}>
            <TextField 
                label="Pesquisar..." 
                value={props.value}
                onChange={(event) => {
                    props.setValue(event.target.value);
                }}
            />
            </div>
            <div style={{marginBottom: "0px", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", overflow: "hidden", width: "80%"}}>
                <ul style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "75%"}}>
                    {
                        props.oracoes.map((item) =>
                          <li className="musica-page-musica-item"><a target="_blank" href={item.caminho_arquivo} style={{color: "black", textDecoration: "none"}}>{item.nome_documento}</a></li>
                        )
                    }
                </ul>
            </div>
        </div>);
}