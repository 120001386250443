import React, {useState, useEffect} from 'react';
import line from "../../images/musica-liturgica/home/line.png";
import Divider from '@mui/material-next/Divider';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { NavLink, useNavigate } from 'react-router-dom';
// import ptBr from 'date-fns/locale/pt-br';
// with date-fns v3.x
import { pt } from 'date-fns/locale/pt';
import axios from 'axios';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import ListItemButton from '@mui/material/ListItemButton';

// baseUrl
import {baseUrl} from "../../services/adalConfig.js";

import "./Oracoes.css";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export default function HomeOracoes(props) {

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [oracoes, setOracoes] = useState({});
    const [expandDict, setExpandDict] = useState({});
    const [pesquisasPossiveis, setPesquisasPossiveis] = useState([]);
    const [jaTeveScroll, setJaTeveScroll] = useState(false);
 
    useEffect(() => {
       function handleResize() {
         setWindowDimensions(getWindowDimensions());
       }
       window.addEventListener('resize', handleResize);
       return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        var getUrl = baseUrl + "/oracoes-missa";

        axios.get(getUrl)
            .then(resp => {
                setOracoes(resp.data);
                var tempDict = {};
                Object.keys(resp.data).map((item) => {
                    tempDict[item] = true;
                })
                setExpandDict(tempDict); 
            });
    }, []);

    useEffect(() => {
        if (jaTeveScroll == false) {
            try {
                var url = window.location.href;
                if (url.includes("#")) {
                    var l = url.split("#");
                    var valor;
                    if (l[1] == "Terco") valor = "Santo Terço";
                    else valor = l[1];
                }
                document.getElementById(valor).scrollIntoView({ behavior: 'smooth' , block: "center", inline: "center"});
                setJaTeveScroll(true);
            } catch (error) {
                console.log("Nole timere");
            }
        }
        
    });

    useEffect(() => {
        var getUrl = baseUrl + "/oracoes-possiveis-pesquisa";

        axios.get(getUrl)
            .then(resp => {
                setPesquisasPossiveis(resp.data);
            });
    }, []);

    function changeExpansion(chave) {
        var newValue = !expandDict[chave];
        var tempDict = {...expandDict};
        tempDict[chave] = newValue;
        setExpandDict(tempDict);
    }

    if (window.innerWidth <= 768) {
       return (<HomeOracoesMobile oracoes={oracoes} expandDict={expandDict} changeExpansion={changeExpansion} pesquisasPossiveis={pesquisasPossiveis}/>);
    } else {
       return (<HomeOracoesDesktop oracoes={oracoes} expandDict={expandDict} changeExpansion={changeExpansion} pesquisasPossiveis={pesquisasPossiveis}/>);
    }
}

function HomeOracoesDesktop(props) {

    const navigate = useNavigate();
    const [value, setValue] = useState("");

    var oracoes = Object.keys(props.oracoes).map((chave, index) => {
        return (
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}  id={chave}>
                <div class="musica-home-header" style={index == 0? {marginTop: "0px", marginBottom: "20px"} : {marginTop: "20px", marginBottom: "20px"}}>
                    <Divider sx={{
                        "&::before, &::after": {
                        borderColor: "#bc1200",
                        },
                    }}>
                        <div style={{display: "flex", alignItems: "center"}}>
                        <ListItemButton onClick={() => props.changeExpansion(chave)} style={{display: "flex", justifyContent: "center"}}>
                                <p style={{fontSize: "25px"}}>{chave}</p>
                                {props.expandDict[chave] ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                        </div>
                    </Divider>
                </div>
                <Collapse in={props.expandDict[chave]} timeout="auto" unmountOnExit style={{width: "75%"}}>
                    <ul style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "90%"}}>
            {props.oracoes[chave].map((item, index) => {
                return (<li class="home-musicas-item-list"><NavLink to={item.url} style={{color: "black", textDecoration: "none"}}>{item.nome_tipo_oracao}</NavLink></li>)
            })
            }
            </ul>   
                </Collapse>
            </div>
    )})

        
    return (
        <div style={{marginBottom: "100px", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", marginTop: "110px", overflow: "hidden"}}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <h1 class="musicas-home-bordao" style={{fontSize: "22px"}}>“<b>Quem reza se salva, quem não reza é condenado.</b> Salvar-se sem rezar é dificilíssimo, até mesmo impossível... mas rezando, a salvação é certa e facilíssima. Se não orarmos, não temos desculpas, porque a graça de rezar é dada a todos... se não nos salvarmos, a culpa será toda nossa, porque não teremos rezado.”</h1>
                <h2 class="musicas-home-bordao-subtitulo">Santo Afonso Maria de Ligório</h2>
            </div>
            <div class="musica-home-header">
                <Divider sx={{
                    "&::before, &::after": {
                    borderColor: "#bc1200",
                    },
                }}>
                    Orações
                </Divider>
            </div>
            <div style={{width: "400px", height: "100px", display: "flex", flexDirection: "column", justifyContent: "center"}}>
                <Autocomplete
                    onChange={(event, newValue) => {
                        navigate(props.pesquisasPossiveis[newValue]);
                    }}
                    options={Object.keys(props.pesquisasPossiveis)}
                    renderInput={(params) => <TextField {...params} label="Pesquisar todas as orações" />}
                />
            </div>
            <div style={{width: "100%"}}>
                {oracoes}
            </div>            
        </div>
    )
}

function HomeOracoesMobile(props) {
    
    const navigate = useNavigate();
    const [value, setValue] = useState("");

    var oracoes = Object.keys(props.oracoes).map((chave, index) => {
        return (
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}  id={chave}>
                <div class="musica-home-header" style={index == 0? {marginTop: "0px", marginBottom: "20px"} : {marginTop: "20px", marginBottom: "20px"}}>
                    <Divider sx={{
                        "&::before, &::after": {
                        borderColor: "#bc1200",
                        },
                    }}>
                        <div style={{display: "flex", alignItems: "center"}}>
                        <ListItemButton onClick={() => props.changeExpansion(chave)} style={{display: "flex", justifyContent: "center"}}>
                                <p style={{fontSize: "25px"}}>{chave}</p>
                                {props.expandDict[chave] ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                        </div>
                    </Divider>
                </div>
                <Collapse in={props.expandDict[chave]} timeout="auto" unmountOnExit style={{width: "75%"}}>
                    <ul style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "90%"}}>
            {props.oracoes[chave].map((item, index) => {
                return (<li style={{ marginBottom: "7px"}} class="home-musicas-item-list"><NavLink to={item.url} style={{color: "black", textDecoration: "none"}}>{item.nome_tipo_oracao}</NavLink></li>)
            })
            }
            </ul>   
                </Collapse>
            </div>
    )})

        
    return (
        <div style={{marginBottom: "100px", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", marginTop: "110px", overflow: "hidden"}}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <h1 class="musicas-home-bordao" style={{fontSize: "18px", width: "90%"}}>“<b>Quem reza se salva, quem não reza é condenado.</b> Salvar-se sem rezar é dificilíssimo, até mesmo impossível... mas rezando, a salvação é certa e facilíssima”</h1>
                <h2 class="musicas-home-bordao-subtitulo" style={{fontSize: "16px"}}>Santo Afonso Maria de Ligório</h2>
            </div>
            <div class="musica-home-header" style={{marginTop: "20px"}}>
                <Divider sx={{
                    "&::before, &::after": {
                    borderColor: "#bc1200",
                    },
                }}>
                    Orações
                </Divider>
            </div>
            <div style={{width: "70%", height: "100px", display: "flex", flexDirection: "column", justifyContent: "center"}}>
                <Autocomplete
                    onChange={(event, newValue) => {
                        navigate(props.pesquisasPossiveis[newValue]);
                    }}
                    options={Object.keys(props.pesquisasPossiveis)}
                    renderInput={(params) => <TextField {...params} label="Pesquisar todas as orações" />}
                />
            </div>
            <div style={{width: "100%"}}>
                {oracoes}
            </div>            
        </div>
    )
}